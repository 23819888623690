
import { defineComponent } from "vue";
// import { trackerSetAction, trackerSetClick } from "@/utils/vertebraSdk";
// import { gtagEvents } from "@/shared/gtagEvents";
import { useHead } from "@vueuse/head";
// import { data } from "@/shared/products";
// import ProductsBoxContainerMobile from "@/components/templates/ProductsBoxContainer/ProductsBoxContainerMobile.vue";
// import ProductsServiceMobile from "@/components/templates/ProductsService/ProductsServiceMobile.vue";
// import ProductsSpecMobile from "@/components/templates/ProductsSpec/ProductsSpecMobile.vue";
import SolutionBannerImageMobile from "@/components/templates/SolutionBannerImage/SolutionBannerImageMobile.vue";
// import ProductsSubjectMobile from "@/components/templates/ProductsSubject/ProductsSubjectMobile.vue";
import SolutionContainerMobile from "@/components/templates/SolutionContainer/SolutionContainerMobile.vue";
import { solutionCommerce } from "../context";

export default defineComponent({
  name: "SolutionCommerceMobile",
  components: {
    // ProductsBoxContainerMobile,
    // ProductsServiceMobile,
    // ProductsSpecMobile,
    SolutionBannerImageMobile,
    // ProductsSubjectMobile,
    SolutionContainerMobile
  },
  setup() {
    useHead({
      title: "솔루션 > 커머스 - 카카오 i 커넥트 라이브",
      link: [
        {
          rel: "canonical",
          href: `${process.env.VUE_APP_BASE_URL}/solutions/commerce`
        }
      ]
    });
    // const trackEventSpecLivecall = () => {
    //   gtagEvents("call-상세스펙");
    //   trackerSetClick("go-call-spec");
    // };
    // const { livecall } = data.mobile;
    // onMounted(() => {
    //   trackerSetAction("상품 Call 페이지");
    // });
    const solutions = solutionCommerce;
    return {
      // livecall,
      // trackEventSpecLivecall,
      solutions
    };
  }
});
